import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import * as REST from '~services/rest';
import { loadStripe } from '@stripe/stripe-js/pure';

const useStripe = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { stripeApiKey, translations } = useSelector((state) => state.domainConfig);
  const [clientSecret, setClientSecret] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  const appearance = {
    theme: 'stripe',
  };

  const initialize = async () => {
    await setStripePromise(loadStripe(stripeApiKey, { locale: translations.substr(0, translations.indexOf('_')) }));
  };

  const fetchIntent = async (personId, currency, amount, locationId, returnUrl) => {
    try {
      setIsLoading(true);
      if (!clientSecret) {
        await initialize();
      }

      const token = await getAccessTokenSilently();
      const result = await REST.post({
        name: `payments`,
        requiresAuthentication: true,
        mockFileName: 'payments/post-prepare-payment',
        params: {
          person_id: personId,
          place_id: locationId,
          currency,
          value: parseInt(amount, 10),
          return_url: returnUrl,
        },
        token,
      });

      const cSecret = result?.data[0]?.client_secret;

      if (!cSecret) {
        setError(true);
        setClientSecret('');
        setIsLoading(false);
        return;
      }

      setClientSecret(cSecret);
      setError(null);
      setIsLoading(false);
    } catch (err) {
      console.log('Error when fetching payment secret', err);
      setError(true);
      setClientSecret('');
      setIsLoading(false);
    }
  };

  return { stripePromise, clientSecret, isLoading, error, appearance, fetchIntent, initialize };
};

export default useStripe;
