import React from 'react';
import { RawIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

import './styles/base.scss';

import axiosProvider from '~services/axiosProvider';
import intlProvider from '~services/intlProvider';

import Notifications from '~components/notification';

import Seo from '~components/seo';
import PaymentRequestModal from '~containers/payment/request/modal';

import AuthProvider from '~contexts/auth';
import UserProvider from '~contexts/user';
import FavoriteProvider from '~contexts/favorite';
import Socket from '~contexts/socket';
import Pusher from '~contexts/pusher';
import PWA from '~contexts/pwa';
import Layout from '~contexts/layout';

// code splitting depends on that, should be shared in all chunks
// eslint-disable-next-line no-unused-vars
import Validators from '~helpers/validators';
// eslint-disable-next-line no-unused-vars
import Homepage from '~containers/homepage';

import { useRawIntl, useLocale, isSeoCrowdin } from './hooks/intl';
import Routes from './routes';
import Crowdin from './components/crowdin';
import DeferReadiness from './components/defer-readiness';

const App = () => {
  const domainConfig = useSelector((state) => state.domainConfig);

  const urlLocation = useLocation();
  const [locale] = useLocale();

  const intlLocale = locale === 'eo_UY' ? 'en-gb' : locale.replace('_', '-').toLowerCase();

  if (!axiosProvider.isInitialized) {
    axiosProvider.initialize({
      baseURL: domainConfig.apiUrl,
      mockURL: '/api/http',
      language: intlLocale,
      domain: domainConfig.domain,
    });
  }

  if (intlProvider.getInstance() && intlProvider.getInstance().previousLocale !== intlLocale) {
    intlProvider.clear();
  }

  const [intlProviderInstance] = useRawIntl(intlLocale, urlLocation);

  if (typeof window !== 'undefined') {
    if (window.Cypress) {
      const history = useHistory();
      if (!window.reactBrowserHistory) {
        window.reactBrowserHistory = history;
      }
    }
  }

  return (
    <PWA>
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) => (
          <React.Fragment>
            <div>You have encountered an error</div>
            <div>{error.toString()}</div>
            <div>{componentStack}</div>
            <button onClick={() => resetError()}>Retry</button>
          </React.Fragment>
        )}
      >
        <Auth0Provider
          domain={domainConfig.auth0Config.domain}
          clientId={domainConfig.auth0Config.clientId}
          authorizationParams={{
            audience: domainConfig.auth0Config.audience,
            redirect_uri: `${domainConfig.protocol}://${domainConfig.domain}/after-login/`,
            ui_locales: domainConfig.auth0Config.locale || 'en',
          }}
        >
          <AuthProvider>
            <Pusher>
              <UserProvider>
                <FavoriteProvider>
                  <DeferReadiness isReady={!!intlProviderInstance}>
                    <Socket
                      domain={domainConfig.websockets.domain}
                      path={domainConfig.websockets.path}
                      addTrailingSlash={domainConfig.websockets.addTrailingSlash}
                    >
                      <>
                        <Crowdin baseEnabled={locale === 'eo_UY'} seoEnabled={isSeoCrowdin(urlLocation)} />
                        <RawIntlProvider value={intlProviderInstance}>
                          <Layout>
                            <Seo>
                              <Routes />
                              <Notifications />
                              <PaymentRequestModal />
                            </Seo>
                          </Layout>
                        </RawIntlProvider>
                      </>
                    </Socket>
                  </DeferReadiness>
                </FavoriteProvider>
              </UserProvider>
            </Pusher>
          </AuthProvider>
        </Auth0Provider>
        <div className="c-notification__container" id="notifications-container" />
      </Sentry.ErrorBoundary>
    </PWA>
  );
};

export default App;
