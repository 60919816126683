import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from '~hooks/common';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { AuthContext } from '~contexts/auth';
import IntakeActions from '~redux/intake';
import { UserContext } from '~contexts/user';
import { getListingRedirect } from '~helpers/common';
import { useListingRedirect } from '~hooks/useListingRedirect';
import { useAuth0 } from '@auth0/auth0-react';

import { useLocationDetails } from '~hooks/useLocationDetails';
import { LocationSearch } from '~components/forms/location';
import { useDefaultWish } from '~containers/wishes/default-wish';

import Button from '~components/button';
import HeroHp from '~components/hero-hp';
import { getWorkersCount } from '../intake/client/utils';

const Header = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const storedIntakeWish = useSelector((state) => state.intake.wish);
  const defaultWish = useDefaultWish();
  const wish = storedIntakeWish || defaultWish;
  const [defaultPrice, setDefaultPrice] = useState(wish?.maximumRate?.amount || null);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [showFields, setShowFields] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  
  const locationIdInUrlParam = queryString.parse(location.search).locationId;
  const showFieldsInParam = queryString.parse(location.search).showFields;
  const { user: authUser } = useAuth0();
  const personId = !authUser ? null : authUser['https://sr2.ca/claims/user_metadata'].uuid;
  const { user: backendUser, getOwnData, updateCustomer } = useContext(UserContext);

  const listingRedirect = useListingRedirect();

  const { locationDetails, pricingDetails, isLoading, requestLocationByID } = useLocationDetails(
    locationIdInUrlParam || null
  );
  

  const handleAddressSubmit = async (newAddress) => {
    requestLocationByID(newAddress.place_id);
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingInitial(false);
    }
  }, [isLoading]);

  const [address, setAddress] = useState(locationDetails);
  const previousLocationDetails = usePrevious(locationDetails);


  useEffect(() => {
    if (!address) {
      setAddress(locationDetails);
    }
  }, [locationDetails]);

  const handleAddressChange = async (loc) => {
    if (!loc) {
      return;
    }

    const newLocation = {
      locationId: loc.place_id,
      address: loc.address,
    };

    setAddress(newLocation);
    handleAddressSubmit(loc);
  };

  useEffect(() => {
    if(showFields) {
      history.push({
        pathname: '/intake/client',
        state: { 
          isFromHome: true, 
          wish,
          pricingDetails,
          defaultPrice,

        },
      }); 
    }
  }, [showFields])

  useEffect(async () => {
    if (locationDetails && pricingDetails) {

      if (!defaultPrice) {
        setDefaultPrice((pricingDetails.maxPrice - pricingDetails.minPrice) * 0.75 + pricingDetails.minPrice);
      }
      const newWish = {  ...wish, address: locationDetails, maximumRate: { amount: pricingDetails.maxPrice, currency: pricingDetails.currency }};

      dispatch(IntakeActions.setAccountType('client'));
      dispatch(IntakeActions.setWish({ ...newWish, published: true }));

      if(showFieldsInParam) {
        setShowFields(true);
        return;
      }

      if(previousLocationDetails?.place_id !== locationDetails.place_id) {
        const resultsCount = await getWorkersCount(locationDetails, pricingDetails, wish);
        if(resultsCount < 4) {
          setShowFields(true);
        } else {
          dispatch(IntakeActions.setWish({ ...newWish, timeframes: [], languages: [], published: false }));
          const redirectAddress = getListingRedirect('client', locationDetails.place_id);
          
          const isUserAlready = await getOwnData(personId);
          if (!isUserAlready) {
            history.push(redirectAddress);
            return;
          }

          const newUser = { ...backendUser, wish: { ...newWish, timeframes: [], languages: [], published: false } };
          await updateCustomer(newUser);
          history.push(redirectAddress);
          
        }
      }
    }
  }, [locationDetails, pricingDetails]);

  return (
    <HeroHp.Wrapper>
      <HeroHp.Subtitle>
        {intl.formatMessage(
          {
            id: 'containers.homepage.seo.hero.subtitle',
            defaultMessage: 'Your house help is waiting',
          }
        )}
      </HeroHp.Subtitle>
      
      <HeroHp.Title>
        {intl.formatMessage({
          id: 'containers.homepage.seo.hero.title',
          defaultMessage: 'Find a great cleaner or client without hassle',
        })}
      </HeroHp.Title>
      
      <HeroHp.Postcode>
        <div className="c-form-section c-form-section--center c-form-section--address">
          <div className="c-form-section__content">
            <div className="c-fieldset">
              <LocationSearch
                isDataLoading={isLoadingInitial}
                location={address}
                placeholder={intl.formatMessage({
                  id: 'containers.wishes.form.fields.address.placeholder',
                  defaultMessage: 'Address',
                })}
                 name="address"
                className="c-autocomplete--focus-disabled"
                onSubmit={(loc) => handleAddressChange(loc)}
              />
                
            </div>
          </div>
        </div>
      </HeroHp.Postcode>

      {!isAuthenticated || !user || !user.accountType ? (
        <HeroHp.Buttons>
          
          <Link to="/intake/worker">
            <Button className="c-btn--text large">
              {intl.formatMessage({
                id: 'containers.homepage.seo.hero.buttons.clients',
                defaultMessage: "I'm looking for a client",
              })}
            </Button>
          </Link>
        </HeroHp.Buttons>
      ) : (
        <HeroHp.Buttons>
          <Link to={listingRedirect}>
            <Button>
              {intl.formatMessage({
                id: `containers.homepage.hero.buttons.overview.${user.accountType}`,
                defaultMessage: 'Go to overview',
              })}
            </Button>
          </Link>
        </HeroHp.Buttons>
      )}

    
      <HeroHp.Intro>
        {intl.formatMessage({
          id: 'containers.homepage.seo.hero.intro',
          defaultMessage:
            'Experience the convenience of domestic help. Get not only domestic help according to your wishes, but also the guarantee that you will remain satisfied!',
        })}
      </HeroHp.Intro>
      
     
    </HeroHp.Wrapper>
  );
};

export default Header;
