import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as REST from '~services/rest';
import { useAuth0 } from '@auth0/auth0-react';

import { AuthContext } from '~contexts/auth';
import { UserContext } from '~contexts/user';

import PaymentActions from '~redux/payment';
import IntakeActions from '~redux/intake';

import { useSearchParams } from '~hooks/common';

const useOpenChat = ({ personId, accountType, locationId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = useSearchParams('on-open-chat');

  const { getAccessTokenSilently } = useAuth0();
  const { isAuthenticated, signIn, signUp } = useContext(AuthContext);

  const { user } = useContext(UserContext);
  const { payedWorkers } = useSelector((state) => state.payment);

  const redirect = () => {
    history.push({ pathname: `/discussion/${personId}` });
  };

  const hasPayedBefore = () => payedWorkers?.some(
    (payed) => payed.customerId === user.personId && payed.workerId === personId
  );

  const fetchIsAllowedToChat = async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await REST.get({
        name: `payments`,
        mockFileName: 'payments/can-chat',
        params: {
          'customer-id': user.personId,
          'worker-id': personId,
        },
        token,
      });
      return result.data.allowed_to_talk || false;
    } catch (err) {
      console.log('Error while fetching subscription details', err);
      return false;
    }
  };

  const openChat = async () => {
    // If not authenticated goto register/login
    if (!isAuthenticated) {
      dispatch(IntakeActions.setReferrer(`/discussion/${personId}`));
      if (searchParams['on-open-chat']) {
        signIn();
        return;
      }
      signUp();
      return;
    }

    // If worker opening clients profile redirect to discussion
    if (accountType === 'client') {
      redirect();
    }

    if (hasPayedBefore()) {
      redirect();
      return;
    }

    const isAllowedToChat = await fetchIsAllowedToChat();
    if (!isAllowedToChat) {
      dispatch(PaymentActions.setModalData(personId, locationId));
      dispatch(PaymentActions.toggleRequestModal(true));
      return;
    }
    dispatch(PaymentActions.addPayedWorker(user.personId, personId));
    redirect();
  };

  const canOpenChat = async ({ pId, aType }) => {
    // If not authenticated goto register
    if (!isAuthenticated) {
      dispatch(IntakeActions.setReferrer(`/discussion/${pId}`));
      signUp();
      return false;
    }

    // If worker opening clients profile redirect to discussion
    if (aType === 'client') {
      return true;
    }

    if (hasPayedBefore()) {
      return true;
    }

    const isAllowedToChat = await fetchIsAllowedToChat();
    if (!isAllowedToChat) {
      dispatch(PaymentActions.setModalData(pId, locationId));
      dispatch(PaymentActions.toggleRequestModal(true));
      return false;
    }
    dispatch(PaymentActions.addPayedWorker(user.personId, personId));
    return true;
  };

  return { openChat, canOpenChat };
};

export { useOpenChat };
